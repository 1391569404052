import axios from "../index";

// C端公众号登录
export function login(data) {
  return axios.postForm("/capi/customer/login/login", data);
}

// 客户贷款信息
export function getCustomersLoanInfo(data) {
  return axios.get("/capi/customersBusiness/getCustomersLoanInfo", data);
}

// 当前应还详情
export function getCurrentLoanDetailList(data) {
  return axios.get("/capi/customersBusiness/getCurrentLoanDetailList", data);
}

// 还款计划表
export function getLoanDetailList(data) {
  return axios.get("/capi/customersBusiness/getLoanDetailList", data);
}

// 获取该客户还款账户
export function getCustomerOrderBank(data) {
  return axios.get("/capi/customersBusiness/getCustomerOrderBank", data);
}
