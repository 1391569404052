import axios from "axios";
import qs from "qs";
import { getToken, getUserId } from "../utils/LocalStorage";

const service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // withCredentials: true,
  timeout: 60000,
});

// 请求拦截
service.interceptors.request.use(
  (config) => {
    if (getToken()) {
      config.headers["token"] = getToken();
    }
    if (getUserId()) {
      config.headers["userId"] = getUserId();
    }
    // config.headers["app"] = "yijinpuhuitest";
    config.headers["app"] = "yijinpuhui";
    if (config.data && config.data.$_isFormData) {
      config.headers["Content-Type"] =
        "application/x-www-form-urlencoded;charset=UTF-8";
      delete config.data.$_isFormData;
      config.data = qs.stringify(config.data);
    } else {
      config.headers["Content-Type"] = "application/json";
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

const request = {
  get: (resUrl, resPar = {}) => {
    return service.get(resUrl, {
      params: resPar,
    });
  },
  post: (resUrl, resPar = {}) => {
    return service.post(resUrl, resPar);
  },
  postForm: (resUrl, resPar = {}) => {
    resPar.$_isFormData = true;
    return service.post(resUrl, resPar);
  },
  put: (resUrl, resPar = {}) => {
    return service.put(resUrl, resPar);
  },
  delete: (resUrl, resPar = {}) => {
    return service.delete(resUrl, {
      params: resPar,
    });
  },
};

export default request;
